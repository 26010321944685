* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* overflow: hidden; */
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.5, 1.5);
  }
}

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.2, 1.2);
  }
}

.carousel-inner .carousel-item .image-overlay > img {
  -webkit-animation: zoom 70s;
  animation: zoom 30s;
}

/*.caption-item {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*    transform: translate(-50%, -50%);*/
/*    color: white;*/
/*    text-align: center;*/
/*}*/

/*.dark:after {*/
/*    content: "";*/
/*    !*display: block;*!*/
/*    position: absolute;*/
/*    top: 0;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    right: 0;*/
/*    background: black;*/
/*    opacity: 0.6;*/
/*}*/
.slick-arrow {
  display: none !important;
}
.product-inner p {
  font-size: 16px;
}
.head-color {
  color: #31499f;
}
.commonhiden {
  overflow: hidden;
}
.poligon {
  text-align: center;
}
/* .custom-slide { */
/*top: 0;*/
/*transform: translateY(-7%);*/
/*background-color: ;*/
/* } */

.custom-bg {
  z-index: 1111 !important;
  background: black;
}

ul li a {
  color: white !important;
  font-size: 18px !important;
  padding: 0 15px !important;
}

.navbar-toggler {
  background-color: white !important;
}

.offcanvas {
  background-color: black !important;
}

.recent-product {
  font-size: 50px;
  padding: 40px 0;
}
.card-image {
  transition: all 0.3s ease-in-out;

  border-radius: 40px 0;
  text-align: center;
}
.card-image:hover {
  transform: scale(1.08);
  cursor: pointer;
}
.custom-carousel-inner {
  height: 100vh !important;
}
.card-image-inner {
  border: 5px solid #ffc107;
  padding: 10px;
  box-shadow: 0 0 10px gray;
  border-radius: 40px 0;
}
.card-image img {
  width: 300px;
  height: 300px;
}
.flex-item {
  display: flex;
  align-items: center;
}

.flex-item-inner {
  background: white;
  padding: 10px;
  border-radius: 10px;
  width: 125%;
  min-height: 500px;
  transform: translateX(-20%);
  box-shadow: 0 0 5px silver;
}

/*.intro-img {*/
/*    border: 10px solid #dbdada;*/
/*    border-radius: 40px 0;*/
/*    padding: 10px;*/
/*}*/
.our-clint-inner img {
  width: 200px;
  height: 100px;
}
.our-clint-inner {
  background: #d6d7dd;
  box-shadow: 0 0 5px;
  padding: 20px;
  border: 1px solid #2196f3;
}
.card-img-top {
  /*width: 300px;*/
  height: 300px;
  object-fit: cover;
}
.intro-img-overlay {
  background-image: url("../introimg/1-9.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
  border-radius: 40px 0;
  padding: 10px;
  background-blend-mode: overlay;
  background-color: #2d2f36;
  position: relative;
}
.intro-img-overlay::before {
  content: "";
  position: absolute;
  width: 60%;
  height: 11px;
  background-color: white;
  right: 3%;
  border-radius: 20px;
  top: 3%;
}
.intro-img-overlay::after {
  content: "";
  position: absolute;
  width: 60%;
  height: 11px;
  background-color: white;
  left: 3%;
  border-radius: 20px;
  bottom: 3%;
}
.bg-pic {
  background-image: url("../bgimage/bgimage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 500px;
  margin: 40px 0;
  background-blend-mode: overlay;
  background-color: #151c38;
  background-attachment: fixed;
}
.bg-pic-main {
  position: relative;
}
.bg-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.our-clint {
  text-align: center;
  padding: 40px 0;
}
.contact-banner-section {
  background-image: url("../bgimage/bgimage.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 500px;
  position: relative;
}
.contact-banner-overlay {
  position: absolute;
  background-color: #31499f;
  min-height: 100%;
  width: 100%;
  opacity: 0.9;
}
.contact-us-title {
  position: absolute;
  bottom: -7%;
  left: 50%;
  color: white;
  padding: 12px;
  border-radius: 10px 10px;
  transform: translateX(-50%);
  box-shadow: 0 0 5px grey;
  background: #31499f;
}
.address-title {
  color: #31499f;
  font-size: 20px;
  margin: 0;
  font-weight: 500;
}
.contact-form-title {
  color: #31499f;
}
.send-mail-btn {
  background-color: #31499f;
  color: white;
}
.send-mail-btn:hover {
  background-color: transparent;
  color: black;
  border: 2px solid gray;
}
.send-mail-btn:disabled {
  background-color: #4167f3;
  cursor: not-allowed;
  color: white;
}
.image-shape {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  padding: 64px;
  background: #31499f;
}
/* .image-shape-tow{
    clip-path: polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%);
} */
.about-img {
  z-index: 1;
  height: 412px;
}
.about-inner {
  background: #fff5ed;
  padding: 5% 5% 5% 20%;
  margin-left: -20%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.about-inner-right {
  background: #fff5ed;
  padding: 5% 20% 5% 5%;
  margin-right: -20%;
}
.download-btn {
  background-color: #31499f;
  color: white;
}
.download-btn:hover {
  border: 1px solid #31499f;
}

.product-margin {
  margin: 5% 0;
}
.nav-item {
  position: relative;
  transition: all 0.3s ease-in-out;
}
.nav-link.active {
  position: relative;
  width: fit-content;
}
.nav-item:hover:after,
.nav-link.active:after {
  content: "";
  position: absolute;
  border-bottom: 1px solid red;
  width: 85%;
  left: 0;
  right: 0;
  /*transform: translateX(0%);*/
  bottom: 0;
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
}

.product-width-and-height {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  padding: 20px;
  background: #31499f;
  height: 300px;
  width: 300px;
}
/* .poligon {
  background: linear-gradient(45deg, #3f51b5, #03a9f400);
} */
/* .product-img {
  display: flex;
} */
@media screen and (max-width: 991px) {
  li.nav-item {
    padding: 10px 0;
  }
  .custom-carousel-inner {
    height: 30vh !important;
  }
  .recent-product {
    padding-bottom: 40px;
  }
}
.line-after {
  text-decoration: underline;
}
.flex-item-inner p {
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 2.8;
  padding: 19px;
  text-align: justify;
}
.bg-color-1 {
  background: red;
  color: white;
}
.bg-color-2 {
  background: yellow;
}
.bg-color-3 {
  background: #31499f;
  color: white;
}

@media only screen and (max-width: 600px) {
  .card-image img {
    width: 100%;
  }
  .contact-us-title {
    width: 100%;
    text-align: center;
  }
  .flex-item-inner {
    width: 100%;
    transform: translate(0px);
    margin-top: 15px;
  }
  .responsive-text {
    font-size: 25px;
  }
  .about-inner {
    margin-left: 0;
    padding: 10px;
    text-align: center;
  }
  .about-inner-right {
    padding: 10px;
    margin-right: 0;
    text-align: center;
    margin-top: 10px;
  }
  .contact-banner-section {
    min-height: 200px;
  }
}
